import React from 'react';

import { Box } from '@nib/layout';
import Copy, { Bold } from '@nib-components/copy';

interface IconPointProps {
  icon: any;
  title?: string | JSX.Element;
  description: string | JSX.Element;
  largeText?: boolean;
  indent?: number;
}

const IconPoint = ({
  icon: Icon,
  title,
  description,
  largeText = true,
  indent = 0,
}: IconPointProps): JSX.Element => (
  <Box display="flex">
    <Box marginLeft={indent * 4} marginRight={4}>
      <Icon fill="trueGreen" />
    </Box>
    <Box display="flex" alignItems="center">
      <Copy large={largeText} measure={false} component="div">
        {title && (
          <Bold color="trueGreen">
            {title}
            <br />
          </Bold>
        )}
        {description}
      </Copy>
    </Box>
  </Box>
);

export default IconPoint;
