import React from 'react';
import Heading from '@nib-components/heading';
import { pt } from 'styled-components-spacing';
import { Tiles, Box } from '@nib/layout';
import Card from '@nib/card';
import styled from 'styled-components';

import asthma from '../../img/non-pharmac/icons/asthma.svg';
import bladderCancer from '../../img/non-pharmac/icons/bladder_cancer.svg';
import breastCancer from '../../img/non-pharmac/icons/breast_cancer.svg';
import cervicalCancer from '../../img/non-pharmac/icons/cervical_cancer.svg';
import colorectalCancer from '../../img/non-pharmac/icons/colorectal_cancer.svg';
import crohnsDisease from '../../img/non-pharmac/icons/crohns_disease.svg';
import leukeamia from '../../img/non-pharmac/icons/leukeamia.svg';
import lungCancer from '../../img/non-pharmac/icons/lung_cancer.svg';
import lymphoma from '../../img/non-pharmac/icons/lymphoma.svg';
import multipleMyelomaCancer from '../../img/non-pharmac/icons/multiple_myeloma_cancer.svg';
import ovarianCancer from '../../img/non-pharmac/icons/ovarian_cancer.svg';
import pancreaticCancer from '../../img/non-pharmac/icons/pancreatic_cancer.svg';
import prostateCancer from '../../img/non-pharmac/icons/prostate_cancer.svg';
import renalCancer from '../../img/non-pharmac/icons/renal_cancer.svg';
import rheumatoidArthritis from '../../img/non-pharmac/icons/rheumatoid_arthritis.svg';
import skinCancer from '../../img/non-pharmac/icons/skin_cancer.svg';

const diseases = [
  {
    title: 'Asthma',
    icon: asthma,
    price: '$59k',
  },
  {
    title: 'Bladder Cancer',
    icon: bladderCancer,
    price: '$137k',
  },
  {
    title: 'Breast Cancer',
    icon: breastCancer,
    price: '$86k',
  },
  {
    title: 'Cervical Cancer',
    icon: cervicalCancer,
    price: '$100k',
  },
  {
    title: 'Colorectal Cancer',
    icon: colorectalCancer,
    price: '$75k',
  },
  {
    title: 'Crohn’s Disease / Inflammatory Bowel',
    icon: crohnsDisease,
    price: '$43k',
  },
  {
    title: 'Lymphoma',
    icon: lymphoma,
    price: '$212k',
  },
  {
    title: 'Leukaemia',
    icon: leukeamia,
    price: '$168k',
  },
  {
    title: 'Lung Cancer',
    icon: lungCancer,
    price: '$167k',
  },
  {
    title: 'Multiple Myeloma Cancer',
    icon: multipleMyelomaCancer,
    price: '$76k',
  },
  {
    title: 'Ovarian Cancer',
    icon: ovarianCancer,
    price: '$10k',
  },
  {
    title: 'Pancreatic Cancer',
    icon: pancreaticCancer,
    price: '$10k',
  },
  {
    title: 'Prostate Cancer',
    icon: prostateCancer,
    price: '$51k',
  },
  {
    title: 'Renal Cancer',
    icon: renalCancer,
    price: '$416k',
  },
  {
    title: 'Rheumatoid Arthritis',
    icon: rheumatoidArthritis,
    price: '$77k',
  },
  {
    title: 'Skin Cancer',
    icon: skinCancer,
    price: '$93k',
  },
];

interface CostBoxProps {
  title: string;
  icon: string;
  price: string;
}

const IconImage = styled.img`
  width: 48px;
  height: 48px;
  ${pt(4)}
`;

const StyledHeading = styled(Heading)`
  & {
    font-weight: normal;
  }
`;

const CostBox = (props: CostBoxProps) => {
  return (
    <Card image={<IconImage src={props.icon} />} align="center" padding={0}>
      <Card.Content title="">
        <Box
          paddingTop={2}
          paddingHorizontal={{ xs: 2, lg: 4 }}
          paddingBottom={{ xs: 2, lg: 4 }}
          flexGrow={1}
        >
          <StyledHeading size={2} component="h4" color="trueGreen">
            {props.price}
          </StyledHeading>
          <StyledHeading size={4} component="h5">
            {props.title}
          </StyledHeading>
        </Box>
      </Card.Content>
    </Card>
  );
};

const NonPharmacDrugCosts = () => {
  return (
    <Tiles space={{ xs: 4, md: 6 }} columns={{ xs: 2, md: 3, xl: 4 }} flex>
      {diseases.map((disease, index) => {
        const { title, icon, price } = disease;
        return <CostBox title={title} icon={icon} price={price} key={index} />;
      })}
    </Tiles>
  );
};

export default NonPharmacDrugCosts;
