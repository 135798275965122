import React from 'react';
import { map } from 'styled-components-breakpoint';
import styled, { css } from 'styled-components';

import { IconProps } from '@nib/icons';
import { colorBrightGreen } from '@nib-components/theme';

const DynamicIcon = (
  component: React.FunctionComponent<IconProps>,
  breakpoints: object
): JSX.Element => {
  const StyledIcon = styled(component)`
    ${({ values }) =>
      map(
        values,
        (val) =>
          css`
            width: ${val}px;
            height: ${val}px;
            color: ${colorBrightGreen};
          `
      )}
  `;

  return <StyledIcon values={breakpoints} />;
};

export default DynamicIcon;
