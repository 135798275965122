import React, { useState, createContext } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import HeroPanel from '@nib-components/hero-panel';
import metrics from '../metrics';
import { ContentfulDocument, UtilityLinkModel } from '../constructs/models';
import Link from '@nib-components/link';
import UtilityButtons from '../components/UtilityButtons';
import CallBackModuleBase from '../components/CallBackModal';
import Heading from '@nib-components/heading';
import Copy, { Bold } from '@nib-components/copy';
import breakpoint from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import ListCopy from '../components/ListCopy';
import { Hidden, Box, Stack, Columns, Column, Section } from '@nib/layout';
import { PopupFeaturePanel } from '../components/PopupFeature';
import styled from 'styled-components';
import colors from '@nib-components/colors';
import { colorTrueGreen } from '@nib-components/theme';

import { HeroPanelCopy } from '../components/styledComponents';

import desktop from '../img/non-pharmac/hero/desktop.jpg';
import desktop2x from '../img/non-pharmac/hero/desktop@2x.jpg';
import mobile from '../img/non-pharmac/hero/mobile.jpg';
import mobile2x from '../img/non-pharmac/hero/mobile@2x.jpg';
import tablet from '../img/non-pharmac/hero/tablet.jpg';
import tablet2x from '../img/non-pharmac/hero/tablet@2x.jpg';

import plansImage from '../img/non-pharmac/plans.jpg';

import treatmentJourney from '../img/non-pharmac/treatmentJourney.jpg';

import {
  PharmaceuticalGraphicIcon,
  DocumentGraphicIcon,
  TreatmentPlanGraphicIcon,
  PriceGraphicIcon,
  DiscountGraphicIcon,
  AccountBlockedGraphicIcon,
} from '@nib/icons';
import IconPoint from '../components/IconPoint';
import { PrimaryButton } from '@nib-components/button';
import TwoLaneSection from '../components/TwoLaneSection';
import NonPharmacWhoPays from '../components/NonPharmacWhoPays';
import DynamicIcon from '../components/DynamicIcon';
import NonPharmacDrugCosts from '../components/NonPharmacDrugCosts';

type QueryNonPharmacPlusTermsAndConditionsResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

type NonPharmacPageProperties = {
  data: {
    nonPharmacPlusTermsAndConditions: QueryNonPharmacPlusTermsAndConditionsResult;
  };
};

const PageContext = createContext({} as NonPharmacPageProperties);

const PageHeroSection = () => (
  <HeroPanel
    images={{
      desktop: desktop,
      desktop2x: desktop2x,
      mobile: mobile,
      mobile2x: mobile2x,
      tablet: tablet,
      tablet2x: tablet2x,
    }}
    title="Cover for non-PHARMAC funded medicines"
    variation="condensed"
  >
    <HeroPanelCopy>
      Our non-PHARMAC medicine cover can provide access to more treatment options when you really
      need them.
    </HeroPanelCopy>
  </HeroPanel>
);

const NotAllDrugsAreFundedSection = () => (
  <Section>
    <Stack space={{ xs: 2, md: 4 }}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen">
        Not all medicines are funded in New Zealand
      </Heading>
      <Copy measure={false}>
        In New Zealand, we have required standards of quality, safety and effectiveness that all
        medicines need to meet.
      </Copy>
      <Margin top={6} bottom={6}>
        <Stack space={{ xs: 4, md: 6 }}>
          <IconPoint
            icon={DocumentGraphicIcon}
            largeText={false}
            description={
              'Medicines that meet NZ standards are approved by Medsafe, ' +
              'the government body that regulates medicines in New Zealand.'
            }
          />
          <IconPoint
            icon={TreatmentPlanGraphicIcon}
            largeText={false}
            description={
              'PHARMAC is the government agency responsible for deciding which ' +
              'medicines are subsidised in New Zealand and makes them available to ' +
              'hospitals and pharmacies at a low cost.'
            }
          />
          <IconPoint
            icon={PharmaceuticalGraphicIcon}
            largeText={false}
            description={
              'Some safe and effective medicines are Medsafe approved but not ' +
              'subsidised by PHARMAC. These are known as non-PHARMAC funded medicines.'
            }
          />
          <IconPoint
            icon={PriceGraphicIcon}
            largeText={false}
            description={
              'Hospitals and pharmacies have to pay full price for non-PHARMAC ' +
              'funded medicines, so if you use them for treatment, you’ll need to pay ' +
              'a higher price compared to subsidised medicines.'
            }
          />
        </Stack>
      </Margin>
    </Stack>
  </Section>
);

const NavigationSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const utilityLinks: UtilityLinkModel[] = [
    {
      title: 'Find the right non-PHARMAC cover for you',
      url: '#find-cover',
    },
    {
      title: 'Learn how it works',
      subtitle: 'Health insurance in NZ',
      url: '/health-insurance',
    },
    {
      subtitle: 'Looking for a plan?',
      title: 'Compare options',
      url: '/compare-plans',
    },
    {
      subtitle: 'Need help joining?',
      title: 'Ask an expert',
      onClick: () => setIsOpen(true),
    },
  ];

  return (
    <Section role="section" background="warmWhite60" padding={4}>
      <CallBackModuleBase variant="Expert" isOpen={isOpen} setOpen={setIsOpen} />
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

const TreatmentJourneyBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: solid;
  border-color: ${colors.sneezy};
  border-radius: 5px;
`;

const WhyYouMightNeedNonPHARMACSection = () => (
  <Section>
    <Box display="flex" justifyContent="center">
      <Box width={{ xs: '95%', lg: '80%' }}>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen" align="center">
            Why you might need a non-PHARMAC funded option
          </Heading>
          <Copy measure={false} align="center">
            Here’s a typical treatment journey in New Zealand that shows how private health
            insurance can support you, compared to getting treatment through the public health
            system.
          </Copy>
          <TreatmentJourneyBox>
            <Hidden below="md">
              <Box
                paddingHorizontal={4}
                display="flex"
                background="white"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <img src={treatmentJourney} />
              </Box>
            </Hidden>
            <Box
              padding={{ xs: 4, xl: 7 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Stack space={{ xs: 2, md: 4 }}>
                <Heading size={3} component="h2" color="trueGreen">
                  A typical treatment journey goes like this:
                </Heading>
                <div>
                  <Bold>Step 1</Bold>
                  <Copy measure={false}>You feel unwell and go to see your doctor.</Copy>
                </div>
                <div>
                  <Bold>Step 2</Bold>
                  <Copy measure={false}>
                    If your GP thinks you need further assessment or treatment, they may refer you
                    to a specialist or hospital.
                  </Copy>
                </div>
                <div>
                  <Bold>Step 3</Bold>
                  <Copy measure={false}>
                    As part of your treatment, you may be prescribed drugs by your specialist.
                  </Copy>
                </div>
              </Stack>
            </Box>
          </TreatmentJourneyBox>
        </Stack>
      </Box>
    </Box>
  </Section>
);

const DrugCoverIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 80px;
  height: 80px;
  border: 6px solid;
  background-color: ${colorTrueGreen};
  border-color: ${colorTrueGreen};
  border-radius: 100%;

  ${breakpoint('lg')`
    width: 125px;
    height: 125px;
    border: 8px solid;
    background-color: ${colorTrueGreen};
    border-color: ${colorTrueGreen};
    border-radius: 100%;
  `}
`;

const TheseDrugsMayNotBeCoveredSection = () => (
  <Section background="sageGreen60">
    <Stack space={{ xs: 2, md: 4 }}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen" align="center">
        These drugs may or may not be covered by PHARMAC
      </Heading>
      <Columns space={{ xs: 6, md: 8 }} collapseBelow="lg">
        <Column>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DrugCoverIconWrapper>
              {DynamicIcon(DiscountGraphicIcon, { xs: 48, lg: 64 })}
            </DrugCoverIconWrapper>
            <Copy measure={false} align="center">
              If they’re covered by PHARMAC your Hospital plan will help pay for these drugs, if
              they’re prescribed by a specialist.
            </Copy>
          </Box>
        </Column>
        <Column>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DrugCoverIconWrapper>
              {DynamicIcon(AccountBlockedGraphicIcon, { xs: 48, lg: 64 })}
            </DrugCoverIconWrapper>
            <Copy measure={false} align="center">
              However, not every treatment has a funded drug available. Sometimes, non-PHARMAC
              funded drugs are the best available, or the only option for your treatment.
            </Copy>
          </Box>
        </Column>
        <Column>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DrugCoverIconWrapper>
              {DynamicIcon(PriceGraphicIcon, { xs: 48, lg: 64 })}
            </DrugCoverIconWrapper>
            <Copy measure={false} align="center">
              If you’re prescribed non-PHARMAC drugs, you’ll need to have non-PHARMAC drug cover
              otherwise you’ll need to pay for them out of your own pocket.
            </Copy>
          </Box>
        </Column>
      </Columns>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen" align="center">
        Who pays?
      </Heading>
      <NonPharmacWhoPays />
    </Stack>
  </Section>
);

const CostExamplesWithNonPHARMACSection = () => (
  <Section>
    <Stack space={{ xs: 2, md: 4 }}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen">
        What will it cost me if I don’t have non-PHARMAC drug cover?
      </Heading>
      <Copy measure={false}>
        If you need a non-PHARMAC funded drug, treatment can potentially end up costing hundreds of
        thousands of dollars. Adding an option for non-PHARMAC drugs to your health insurance will:
      </Copy>
      <ListCopy measure={false}>
        <ul>
          <li>Give you more control over your treatment options</li>
          <li>Allow you to access more expensive treatments</li>
          <li>Reduce the cost for you and your loved ones when this treatment is required</li>
        </ul>
      </ListCopy>
      <Copy measure={false}>
        Here are some approximate cost examples of non-PHARMAC drug treatments, without non-PHARMAC
        drug cover*.
      </Copy>
      <NonPharmacDrugCosts />
      <Copy measure={false}>
        *Costs are based on an average person of 75kg with an average BMI (Body Mass Index), and
        costings are from PHARMAC submissions, https://www.eviq.org.au/ and www.Drugs.com compiled
        in August 2021. Indicative costs shown are either annual estimates or average indicative
        costs for a round of treatment. This is not a representation of conditions covered on your
        policy or the amount that would be paid by nib in the event of a claim.
      </Copy>
    </Stack>
  </Section>
);

const NonPharmacSimpleSummary = () => (
  <PopupFeaturePanel>
    {[
      '$50,000 every policy year',
      '$100,000 every policy year',
      '$200,000 every policy year',
      '$300,000 every policy year',
    ].map((n) => (
      <PopupFeaturePanel.PopupFeature icon={PharmaceuticalGraphicIcon}>
        {n}
      </PopupFeaturePanel.PopupFeature>
    ))}
  </PopupFeaturePanel>
);

class FindTheRightCoverSection extends React.Component {
  static contextType = PageContext;
  render = () => (
    <Section background="sunsetPink60">
      <Stack space={{ xs: 2, md: 4 }}>
        <Heading id="find-cover" size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen">
          Find the right cover for you
        </Heading>
        <Copy measure={false}>
          To give you peace of mind, we can provide cover for Medsafe-approved, non-PHARMAC funded
          drugs prescribed in line with Medsafe’s guidelines as an option added to your eligible
          Hospital plan.
        </Copy>
        <Copy measure={false}>
          You can choose the level of cover that’s right for you with our non-PHARMAC Plus option.
          The cover level is the maximum amount we’ll pay towards your eligible claims every policy
          year for each member covered.
        </Copy>
        <NonPharmacSimpleSummary />
        <Copy measure={false}>
          This is a summary of the non-PHARMAC cover only. You can find out more details about{' '}
          <Link href="/compare-plans">non-PHARMAC Plus online</Link>, in your policy document or
          view the full{' '}
          <Link
            href={
              this.context?.data?.nonPharmacPlusTermsAndConditions?.edges[0]?.node?.document.file
                .url
            }
          >
            {' '}
            non-PHARMAC terms and conditions.
          </Link>
        </Copy>
      </Stack>
    </Section>
  );
}

const AddingNonPHARMACPlusSection = () => (
  <Section>
    <TwoLaneSection
      images={{ desktop: plansImage, mobile: plansImage }}
      useFixedWidthImage={false}
      showEntireImage={true}
    >
      <TwoLaneSection.Text>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen">
            Adding non-PHARMAC Plus
          </Heading>
          <Copy>
            To help you choose the right level of cover, here are some of the key things that you
            might want to consider as part of your decision.
          </Copy>
          <Copy>
            You can add non-PHARMAC Plus to your eligible Hospital plan - for any member on your
            policy at any time.
          </Copy>
          <Copy>
            If you’re new to nib, you can buy non-PHARMAC Plus with a Hospital plan directly from us
            or talk to your independent financial adviser about the options that are available to
            you.
          </Copy>
          <Copy>
            If you already have a health insurance plan with nib, adding non-PHARMAC Plus is easy.
            Simply fill out our{' '}
            <Link href="https://health.nib.co.nz/contact-us/add-or-remove-an-option">
              online form
            </Link>
            .
          </Copy>
        </Stack>
      </TwoLaneSection.Text>
      <TwoLaneSection.Subtext>
        <PrimaryButton href="/compare-plans">Compare plans</PrimaryButton>
      </TwoLaneSection.Subtext>
    </TwoLaneSection>
  </Section>
);

const schema = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'WebPage',
  identifier: 'https://www.nib.co.nz/non-pharmac',
  url: 'https://www.nib.co.nz/non-pharmac',
  description:
    'non-PHARMAC Plus gives you cover for the cost of some drugs that aren’t funded by PHARMAC',
  name: ['non-PHARMAC funded drug cover | nib'],
  isPartOf: 'https://www.nib.co.nz',
};
const JSONschema = JSON.stringify(schema);

const NonPharmacPage = (props: NonPharmacPageProperties): JSX.Element => (
  <Layout>
    <Helmet>
      <title>Non Pharmac Health Insurance | nib</title>
      <meta
        name="description"
        content="non-PHARMAC Plus gives you cover for the cost of some drugs that aren’t funded by PHARMAC"
      />
      <script type="application/ld+json">{JSONschema}</script>
    </Helmet>
    <PageContext.Provider value={props}>
      <PageHeroSection />
      <NotAllDrugsAreFundedSection />
      <NavigationSection />
      <WhyYouMightNeedNonPHARMACSection />
      <TheseDrugsMayNotBeCoveredSection />
      <CostExamplesWithNonPHARMACSection />
      <FindTheRightCoverSection />
      <AddingNonPHARMACPlusSection />
    </PageContext.Provider>
  </Layout>
);

export const ComparePlansPageQuery = graphql`
  query NonPharmacPageQuery {
    nonPharmacPlusTermsAndConditions: allContentfulDocument(
      filter: { name: { eq: "non-PHARMAC Plus add-on DTC" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

export default metrics({ pageName: 'non-pharmac' })(NonPharmacPage);
