import React from 'react';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { m } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { Box, Columns, Column } from '@nib/layout';
import styled from 'styled-components';
import Card from '@nib/card';

import {
  DoctorGraphicIcon,
  SurgeryGraphicIcon,
  PharmaceuticalGraphicIcon,
  InjectionGraphicIcon,
} from '@nib/icons';
import { PrimaryButton } from '@nib-components/button';
import { colorTrueGreen } from '@nib-components/theme';

const LineBox = styled.div`
  background-color: ${colorTrueGreen};
  ${m(3)};

  ${breakpoint('xs')`
    height: 2px;
    width 95%;
  `};

  ${breakpoint('lg')`
    height: 95%;
    width 2px;
  `};
`;

const WhoPaysNonPharmac = () => (
  <Columns space={{ xs: 2, md: 4 }} collapseBelow="xl" verticalAlign="stretch">
    <Column width="1/4" flex>
      <Card padding={0}>
        <Card.Content title="">
          {/* Title is required but we don't want to use one*/}
          <Box
            display="flex"
            flexDirection="column"
            padding={{ xs: 4, md: 4 }}
            alignItems="center"
            height="100%"
          >
            <Box padding={{ xs: 4, md: 6 }}>
              <DoctorGraphicIcon size="xl" fill="trueGreen" />
            </Box>
            <Box paddingBottom={{ xs: 4, md: 6 }} textAlign="center">
              <Heading size={3} color="trueGreen" component="h3">
                GP Consultation
              </Heading>
            </Box>
            <Copy align="center" measure={false}>
              You pay or nib may pay if you have private health insurance
            </Copy>
          </Box>
        </Card.Content>
        <Card.Footer>
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <PrimaryButton
              size="large"
              variant="primary"
              href="/compare-plans?plan=everyday"
              component="a"
            >
              Everyday Plans
            </PrimaryButton>
          </Box>
        </Card.Footer>
      </Card>
    </Column>
    <Column width="1/2" flex>
      <Card padding={0}>
        <Card.Content title="">
          {/* Title is required but we don't want to use one*/}
          <Box display="flex" flexDirection="column" padding={{ xs: 4, md: 4 }} alignItems="center">
            <Box paddingBottom={{ xs: 4, md: 6 }}>
              <Columns collapseBelow="lg">
                <Column>
                  <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <Box padding={{ xs: 4, md: 6 }}>
                      <SurgeryGraphicIcon size="xl" fill="trueGreen" />
                    </Box>
                    <Heading size={3} color="trueGreen" component="h3">
                      Referred for treatment – specialist or hospital
                    </Heading>
                  </Box>
                </Column>
                <Column flex width="content">
                  <LineBox />
                </Column>
                <Column>
                  <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <Box padding={{ xs: 4, md: 6 }}>
                      <PharmaceuticalGraphicIcon size="xl" fill="trueGreen" />
                    </Box>
                    <Heading size={3} color="trueGreen" component="h3">
                      PHARMAC drugs needed for treatment
                    </Heading>
                  </Box>
                </Column>
              </Columns>
            </Box>
            <Copy align="center" measure={false}>
              Public health system pays – risk of long wait times and less control over who treats
              you and when
            </Copy>
          </Box>
        </Card.Content>
        <Card.Footer>
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <PrimaryButton
              size="large"
              variant="primary"
              href="/compare-plans?plan=hospital"
              component="a"
            >
              Hospital Plans
            </PrimaryButton>
          </Box>
        </Card.Footer>
      </Card>
    </Column>
    <Column width="1/4" flex>
      <Card padding={0}>
        <Card.Content title="">
          {/* Title is required but we don't want to use one*/}
          <Box display="flex" flexDirection="column" padding={{ xs: 4, md: 4 }} alignItems="center">
            <Box padding={{ xs: 4, md: 6 }}>
              <InjectionGraphicIcon size="xl" fill="trueGreen" />
            </Box>
            <Box paddingBottom={{ xs: 4, md: 6 }} textAlign="center">
              <Heading size={3} color="trueGreen" component="h3">
                non-PHARMAC drugs needed for treatment
              </Heading>
            </Box>
            <Copy align="center" measure={false}>
              You pay or nib may pay if you have private health insurance
            </Copy>
          </Box>
        </Card.Content>
        <Card.Footer>
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <PrimaryButton
              size="large"
              variant="primary"
              href="/compare-plans?plan=combine#non-pharmac"
              component="a"
            >
              non-PHARMAC Plus
            </PrimaryButton>
          </Box>
        </Card.Footer>
      </Card>
    </Column>
  </Columns>
);

export default WhoPaysNonPharmac;
